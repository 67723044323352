<template>
    <div class="container">
        <div>
            <el-form :inline="true"  class="demo-form-inline">
  <el-form-item label="课程名称">
    <el-input v-model="title" placeholder="课程名称"></el-input>
  </el-form-item>
  <el-form-item label="机构">
   <mechanism-select :mecid.sync = 'mechanism_id'></mechanism-select>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="search" :loading="loading">查询</el-button>
  </el-form-item>
  <el-form-item>
    <el-button type="success" @click="edit">提交课程</el-button>
  </el-form-item>
</el-form>
        </div>
        <div class="change">
            <el-transfer
      style="text-align: left;width: 80%;" 
      v-model="checkList"
      :titles="['天赋课列表','已选课程']"
      :props="{
      key: 'id',
      label: 'title'
    }"
      :data="data">
      <span slot-scope="{ option }">{{ option.title }} - {{ option.map.masterMechanismEntity.mechanism_name || '' }}</span>
      <div slot="left-footer" >
        <el-pagination
        @current-change="getList"
        :currentPage.sync="currentPage"
        :page-size="20"
    layout="total,prev, next,jumper"
    :total="total">
  </el-pagination>
      </div>
    </el-transfer>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            checkList:[],
            data:[],
            title:'',
            mechanism_id:'',
            currentPage:1,
            total:0,
            loading:false,
            userCourse:[]
        };
    },

    mounted() {
        this.getCardCourseList()
        this.getList()
    },

    methods: {
        getCardCourseList(){
            this.$axios({
                url:'user/masterSetPrice/getListByMemberId',
                params:{
                    memberId:this.$route.query.member_id,
                }
            }).then(res=>{
                if(res.data.data.length>0){
                    this.userCourse = res.data.data
                    this.data = this.data.concat(this.userCourse)
                    let arr = []
                    this.data = this.data.filter((item)=>{
                        if(arr.includes(item.id)){
                            return false
                        }else{
                            arr.push(item.id)
                            return true
                        }
                    })
               this.checkList = res.data.data.map(item=>item.id)
                }
            })
        },
        edit(){
            this.$confirm('确认提交天赋卡的课程吗').then(()=>{
                this.$axios({
                url:'user/memberCourseRelevance/batchInsert',
                method:'post',
                data:{
                    member_id:this.$route.query.member_id,
                    courseIds : this.checkList
                }
            }).then(res=>{
               this.$message('提交成功')
               this.getCardCourseList()
            })
            })
        },
        search(){
            this.currentPage = 1
            this.loading=true
            this.getList()
        },
        getList(){
            this.$axios({
                url:'user/masterSetPrice/queryByMessage',
                params:{
                    status:2,
                    pageSize:20,
                    type:'mechanism_offline',
                    card_type:'优学卡',
                    currentPage:this.currentPage,
                    title:this.title || null,
                    mechanism_id: this.mechanism_id || null
                }
            }).then(res=>{
               this.data = res.data.data.rows
               this.data = this.data.concat(this.userCourse)
               let arr = []
               this.data = this.data.filter((item)=>{
                        if(arr.includes(item.id)){
                            return false
                        }else{
                            arr.push(item.id)
                            return true
                        }
                    })
               this.total = res.data.data.total
               this.loading=false
            }).catch(err=>{
                this.loading=false
            })
        }
    },
};
</script>

<style lang="less" scoped>
 /deep/ .el-transfer-panel{
    width: 25rem;
    height: 37rem;
}
/deep/ .el-transfer-panel__list{
    width: 25rem;
    height: calc(37rem - 80px);
}
</style>